import { IProperty } from "./property.model";

export interface IProfile {
    id?: string;
    email?: string;
    accountType?: 'personal' | 'business';
    firstName?: string;
    lastName?: string;
    authorities?: string[];
    createdBy?: string;
    createdDate?: {"_seconds": number, "_nanoseconds": number};
    lastModifiedBy?: string;
    lastModifiedDate?: {"_seconds": number, "_nanoseconds": number};
    subscription?: 'basic' | 'personal' | 'business';
    properties?: IProperty[];
    lastSignInTime?: String,
    emailVerified?: boolean
}

export const defaultValue: Readonly<IProfile> = {};