import { IProfile } from '../../../shared/models/profile.model';
import { REQUEST, SUCCESS } from '../../../shared/reducers/action-type.util';
import { callTypes } from './reducer';

export const ACTION_TYPES = {
  FETCH_PROFILES: 'profiles/FETCH_PROFILES',
  FETCH_PROFILE: 'profiles/FETCH_PROFILE',
  CREATE_PROFILE: 'profiles/CREATE_PROFILE',
  UPDATE_PROFILE: 'profiles/UPDATE_PROFILE',
  DELETE_PROFILE: 'profiles/DELETE_PROFILE',
};

export const actions = {
  getProfileList: (queryParams) => ({
    type: REQUEST(ACTION_TYPES.FETCH_PROFILES),
    callType: callTypes.list,
    queryParams,
  }),

  getProfileById: (id: string) => ({
    type: REQUEST(ACTION_TYPES.FETCH_PROFILE),
    callType: callTypes.action,
    id,
  }),

  createProfile: (profile: IProfile) => ({
    type: REQUEST(ACTION_TYPES.CREATE_PROFILE),
    callType: callTypes.action,
    profile: profile,
  }),

  updateProfile: (profile: IProfile) => ({
    type: REQUEST(ACTION_TYPES.UPDATE_PROFILE),
    callType: callTypes.action,
    profile: profile,
  }),

  deleteProfile: (id: string) => ({
    type: REQUEST(ACTION_TYPES.DELETE_PROFILE),
    callType: callTypes.action,
  }),
};
