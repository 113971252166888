import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux';
import profilesReducer, {
  ProfilesState,
} from '../app/modules/profiles/_redux/reducer';
import * as profilesSagas from '../app/modules/profiles/_redux/sagas';
import * as statsSagas from '../app/modules/stats/_redux/sagas';
import statsReducer, {StatsState} from '../app/modules/stats/_redux/reducer';
import * as auth from '../app/modules/Auth/_redux/authRedux';
import * as user from '../ducks/profile/reducer';
import * as userProperties from '../ducks/properties/reducer';

export interface IRootState {
  readonly stats: StatsState;
  readonly usersListState: user.UserListState;
  readonly profiles: ProfilesState;
  readonly userPropertiesListState: userProperties.UserPropertyListState;
  readonly auth: typeof auth.initialAuthState;
}

export const rootReducer = combineReducers<IRootState>({
  stats: statsReducer,
  auth: auth.reducer,
  profiles: profilesReducer,
  usersListState: user.reducer,
  userPropertiesListState: userProperties.reducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    user.saga(),
    userProperties.saga(),
    profilesSagas.saga(),
    statsSagas.saga(),
  ]);
}
