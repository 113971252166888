import React, { EffectCallback, useEffect } from 'react';
import { ProfilesWidget } from "../widgets";
import { connect } from 'react-redux'
import { IRootState } from '../../../redux/rootReducer';
import * as statsActions from '../../../app/modules/stats/_redux/actions';

export interface IStatsProps extends StateProps, DispatchProps {}

function MainDashboard(props: IStatsProps) {

  // Fetch stats store (Redux)
  const { personalProfilesStat, businessProfilesStat } = props.stats;

  useEffect(() => {
    props.fetchPersonalStats();
    props.fetchBusinessStats();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xl-6">
          <ProfilesWidget
            className="gutter-b card-stretch"
            elementId="kt_mixed_personal_widget_chart"
            heading="Personal Profiles"
            chartOptions={{
              color: "primary",
              data: personalProfilesStat.chartOptions.bars,
              labels: personalProfilesStat.chartOptions.labels
            }}
            data={{
              rows: [
                [
                  {label: "Total Profiles", value: personalProfilesStat.data.total},
                  {label: "Subscribers", value: personalProfilesStat.data.subscribers}
                ],
                [
                  {label: "Verified", value: personalProfilesStat.data.verified},
                  {label: "Unverified", value: personalProfilesStat.data.unverified}
                ]
              ]
            }}
          />
        </div>
        <div className="col-xl-6">
          <ProfilesWidget
            className="gutter-b card-stretch"
            elementId="kt_mixed_business_widget_chart"
            heading="Business Profiles"
            chartOptions={{
              color: "success",
              data: businessProfilesStat.chartOptions.bars,
              labels: businessProfilesStat.chartOptions.labels
            }}
            data={{
              rows: [
                [
                  {label: "Total Profiles", value: businessProfilesStat.data.total},
                  {label: "Subscribers", value: businessProfilesStat.data.subscribers}
                ],
                [
                  {label: "Verified", value: businessProfilesStat.data.verified},
                  {label: "Unverified", value: businessProfilesStat.data.unverified}
                ]
              ]
            }}
          />
        </div>
      </div>
    </>
  );
}

// TODO: fix the below
const mapStateToProps = ({ stats }: IRootState) => ({
  stats,
});

const mapDispatchToProps = {
  fetchPersonalStats: statsActions.actions.getPersonalStats,
  fetchBusinessStats: statsActions.actions.getBusinessStats,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MainDashboard);

