import { IChartOptions } from "./chartOptions.model";

export interface IProfilesStat {
  chartOptions: IChartOptions;
  data: {
    total: number;
    verified: number;
    unverified: number;
    subscribers: number;
  }
}

export const defaultValue: IProfilesStat = {
  chartOptions: {bars: [], labels: []} as IChartOptions,
  data: {
    total: 0,
    verified: 0,
    unverified: 0,
    subscribers: 0,
  }
};
