import { IChartOptions } from '../../../shared/models/chartOptions.model';
import { defaultValue, IProfilesStat } from '../../../shared/models/profilesStats.model';
import {
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../../shared/reducers/action-type.util';
import { ACTION_TYPES } from './actions';

export const callTypes = {
  action: 'action',
};

export const initialStatsState = {
  loading: false,
  personalProfilesStat: defaultValue,
  businessProfilesStat: defaultValue,
  lastError: null,
};

export type StatsState = Readonly<typeof initialStatsState>;

const reducer = (
  state: StatsState = initialStatsState,
  action
): StatsState => {
  const loading = action.callType === callTypes.action ? true : false;
  switch (action.type) {
    case FAILURE(ACTION_TYPES.FETCH_PERSONAL_STATS):
      return {
        ...state,
        loading: false,
        lastError: action.payload,
      };

    case REQUEST(ACTION_TYPES.FETCH_PERSONAL_STATS):
      return {
        ...state,
        lastError: null,
        loading: !loading,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PERSONAL_STATS):
      return {
        ...state,
        personalProfilesStat: {data: action.payload.profilesStat, chartOptions: action.payload.chartOptions},
        loading: false,
        lastError: null,
      };

    case SUCCESS(ACTION_TYPES.FETCH_BUSINESS_STATS):
      return {
        ...state,
        businessProfilesStat: {data: action.payload.profilesStat, chartOptions: action.payload.chartOptions},
        loading: false,
        lastError: null,
      };

    default:
      return state;
  }
};

export default reducer;
