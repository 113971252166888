import axios from "axios";
import firebase, { firestore, firebaseAuth } from "../../../../firebase";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = "api/auth/forgot-password";
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/me`;


export function requestLogin(email, password) {
  return firebaseAuth.signInWithEmailAndPassword(email, password).then(user => {
    return { email: user.user.email, authToken: user.user.refreshToken, uid: user.user.uid }
  });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  const user = firebase.auth().currentUser;
  if (user) {
    return firestore.collection('profiles').doc(user.uid).get().then(snapshot => {
      return {
        data: {
          emailVerified: user.emailVerified,
          photoURL: user.photoURL,
          id: user.uid,
          ...snapshot.data()
        }
      };
    })
  } else {
    return { data: undefined }
  }
}

export function requestUpdateUser(user) {
  return firestore.doc(`profiles/${user.id}`).update(user);
}