import { REQUEST, SUCCESS } from '../../../shared/reducers/action-type.util';
import { callTypes } from './reducer';

export const ACTION_TYPES = {
  FETCH_PERSONAL_STATS: 'stats/FETCH_PERSONAL_STATS',
  FETCH_BUSINESS_STATS: 'stats/FETCH_BUSINESS_STATS',
};

export const actions = {
  getPersonalStats: () => ({
    type: REQUEST(ACTION_TYPES.FETCH_PERSONAL_STATS),
    callType: callTypes.action
  }),

  getBusinessStats: () => ({
    type: REQUEST(ACTION_TYPES.FETCH_BUSINESS_STATS),
    callType: callTypes.action
  }),
};