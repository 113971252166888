import {
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../../shared/reducers/action-type.util';
import { IProfile, defaultValue } from '../../../shared/models/profile.model';
import { ACTION_TYPES } from './actions';

export const initialProfileState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: [] as Array<IProfile>,
  profileForEdit: defaultValue,
  lastError: null,
};

export type ProfilesState = Readonly<typeof initialProfileState>;

export const callTypes = {
  list: 'list',
  action: 'action',
};

const reducer = (
  state: ProfilesState = initialProfileState,
  action
): ProfilesState => {
  const listLoading = action.callType === callTypes.list ? true : false;
  switch (action.type) {
    case FAILURE(ACTION_TYPES.UPDATE_PROFILE):
    case FAILURE(ACTION_TYPES.FETCH_PROFILE):
    case FAILURE(ACTION_TYPES.FETCH_PROFILES):
      return {
        ...state,
        listLoading: false,
        actionsLoading: false,
        lastError: action.payload,
      };

    case REQUEST(ACTION_TYPES.UPDATE_PROFILE):
    case REQUEST(ACTION_TYPES.FETCH_PROFILE):
    case REQUEST(ACTION_TYPES.FETCH_PROFILES):
      return {
        ...state,
        lastError: null,
        listLoading: listLoading,
        actionsLoading: !listLoading,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PROFILES):
      return {
        ...state,
        // totalCount: action.payload.length,
        totalCount: 1000,
        entities: action.payload,
        listLoading: false,
        actionsLoading: false,
        lastError: null,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PROFILE):
      return {
        ...state,
        profileForEdit: action.payload,
        listLoading: false,
        actionsLoading: false,
        lastError: null,
      };

    case SUCCESS(ACTION_TYPES.UPDATE_PROFILE):
      // Replace the old entity with the updated one
      const updatedEntities = state.entities.map((entity) => {
        if (entity.id === action.payload.profile.id) {
          return action.payload.profile;
        }
        return entity;
      });
      return {
        ...state,
        entities: updatedEntities,
        profileForEdit: defaultValue,
        listLoading: false,
        actionsLoading: false,
      };

    case SUCCESS(ACTION_TYPES.CREATE_PROFILE):
      const newEntities = state.entities;
      newEntities.push(action.payload.profile);
      return {
        ...state,
        entities: newEntities,
        profileForEdit: defaultValue,
        listLoading: false,
        actionsLoading: false,
        lastError: null,
      };

    default:
      return state;
  }
};

export default reducer;
