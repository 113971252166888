export interface IProfile {
  id: any;
  firstName: any;
  lastName: any;
  email: any;
  userName: any;
  gender: any;
  status: any;
  dateOfBbirth: any;
  ipAddress: any;
  type: any;
  _userId: any;
  _createdDate: any;
  _updatedDate: any;
}

export const defaultValue: IProfile = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  userName: '',
  gender: '',
  status: '',
  dateOfBbirth: '',
  ipAddress: '',
  type: '',
  _userId: '',
  _createdDate: '',
  _updatedDate: '',
};
