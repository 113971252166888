import {
  findProfiles,
  getCustomerById,
  updateCustomer,
  createCustomer,
} from './requests';
import {
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../../shared/reducers/action-type.util';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { ACTION_TYPES } from './actions';

// sagas
export function* saga() {
  yield takeLatest(
    REQUEST(ACTION_TYPES.FETCH_PROFILES),
    function* handleGetAllProfiles(action: any) {
      try {
        const { data: payload } = yield call(findProfiles, action.queryParams);
        yield put({ type: SUCCESS(ACTION_TYPES.FETCH_PROFILES), payload });
      } catch (e) {
        console.error('Failed to fetch all profiles: ', e.message);
        yield put({
          type: FAILURE(ACTION_TYPES.FETCH_PROFILES),
          payload: e.message,
        });
      }
    }
  );
  yield takeLatest(
    REQUEST(ACTION_TYPES.FETCH_PROFILE),
    function* handleGetProfileById(action: any) {
      try {
        const { data: payload } = yield call(getCustomerById, action.id);
        yield put({ type: SUCCESS(ACTION_TYPES.FETCH_PROFILE), payload });
      } catch (e) {
        console.error('Failed to fetch profile: ', e.message);
        yield put({
          type: FAILURE(ACTION_TYPES.FETCH_PROFILE),
          payload: e.message,
        });
      }
    }
  );

  yield takeLatest(
    REQUEST(ACTION_TYPES.UPDATE_PROFILE),
    function* handleUpdateProfile(action: any) {
      try {
        yield call(updateCustomer, action.profile);
        yield put({
          type: SUCCESS(ACTION_TYPES.UPDATE_PROFILE),
          payload: { profile: action.profile },
        });
      } catch (e) {
        console.error('Failed to update profile: ', e.message);
        yield put({
          type: FAILURE(ACTION_TYPES.UPDATE_PROFILE),
          payload: e.message,
        });
      }
    }
  );

  yield takeLatest(
    REQUEST(ACTION_TYPES.CREATE_PROFILE),
    function* handleCreateCustomer(action: any) {
      try {
        const { data: profile } = yield call(createCustomer, action.profile);
        yield put({
          type: SUCCESS(ACTION_TYPES.CREATE_PROFILE),
          payload: { profile },
        });
      } catch (e) {
        console.error('Failed to create a profile: ', e.message);
        yield put({
          type: FAILURE(ACTION_TYPES.CREATE_PROFILE),
          payload: e.message,
        });
      }
    }
  );
}
