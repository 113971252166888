import firebase from 'firebase';

const stagingConfig = {
  apiKey: "AIzaSyCtVfnfjhnc-hDux40J7CzjqgP92v5M1H4",
  authDomain: "staging1investmentpros.firebaseapp.com",
  databaseURL: "https://staging1investmentpros.firebaseio.com",
  projectId: "staging1investmentpros",
  storageBucket: "staging1investmentpros.appspot.com",
  messagingSenderId: "659550862679",
  appId: "1:659550862679:web:15521169ad3be692a15212"
};

const prodConfig = {
  apiKey: "AIzaSyAaIZJvhl6Og2S6I9DTAwOC5TsIdvas5e0",
  authDomain: "propertycalculators.firebaseapp.com",
  databaseURL: "https://propertycalculators.firebaseio.com",
  projectId: "propertycalculators",
  storageBucket: "propertycalculators.appspot.com",
  messagingSenderId: "313100491278",
  appId: "1:313100491278:web:eadf32f3e98f6a5cfdfa5f",
  measurementId: "G-1FS0LNYTPY"
};

const config = process.env.REACT_APP_ENV === 'prod' ? prodConfig : stagingConfig;
  firebase.initializeApp(config);
  export const firebaseAuth = firebase.auth();
  export const firestore = firebase.firestore();

  export default firebase;