import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../../redux/rootReducer';
import * as userReducer from '../../../ducks/profile/reducer';
import SVG from 'react-inlinesvg';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import Moment from 'react-moment';
import { Spinner } from '../../../_metronic/layout';

export interface IListUsersProps extends StateProps, DispatchProps {}

function ListUsers(props: IListUsersProps) {
  const [listType, setListType] = useState('personal');
  const { filteredList, loading } = props;

  useEffect(() => {
    props.getUserList();
  }, []);

  const onListTypeClick = (e) => {
    setListType(e);
    props.filterList(e);
  };

  return (
    <>
      <div className={`card card-custom card-stretch gutter-b`}>
        {/* begin::Header */}
        <div className='card-header border-0 py-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label font-weight-bolder text-dark'>
              {listType} Profiles
            </span>
            <span className='text-muted mt-3 font-weight-bold font-size-sm'>
              {filteredList.length} {listType} profile(s)
            </span>
          </h3>
          <div className='card-toolbar'>
            <button
              className='btn btn-info font-weight-bolder font-size-sm mr-3'
              onClick={() => onListTypeClick('personal')}
            >
              Personal
            </button>
            <button
              className='btn btn-danger font-weight-bolder font-size-sm'
              onClick={() => onListTypeClick('business')}
            >
              Business
            </button>
          </div>
        </div>
        {/* end::Header */}

        {/* begin::Body */}
        <div className='card-body pt-0 pb-3'>
          <div className='tab-content'>
            {/* begin::Table */}
            <div className='table-responsive'>
              <table className='table table-head-custom table-vertical-center table-head-bg table-borderless'>
                <thead>
                  <tr className='text-left'>
                    <th
                      style={{
                        minWidth: '250px',
                      }}
                      className='pl-7'
                    >
                      <span className='text-dark-75'>Name/email</span>
                    </th>
                    <th
                      style={{
                        minWidth: '120px',
                      }}
                    >
                      Subscription
                    </th>
                    <th
                      style={{
                        minWidth: '100px',
                      }}
                    >
                      Last login
                    </th>
                    <th
                      style={{
                        minWidth: '100px',
                      }}
                    >
                      Properties
                    </th>
                    <th
                      style={{
                        minWidth: '100px',
                      }}
                    >
                      Email status
                    </th>
                    <th
                      style={{
                        minWidth: '100px',
                      }}
                    />
                  </tr>
                </thead>
                <tbody>
                  {loading && (
                    <tr>
                      <td>
                        <Spinner />
                      </td>
                    </tr>
                  )}
                  {filteredList && (
                    <>
                      {filteredList.map((p) => (
                        <tr key={p.id}>
                          <td className='pl-0 py-8'>
                            <div className='d-flex align-items-center'>
                              <div className='symbol symbol-50 symbol-light mr-4'>
                                <span className='symbol-label'>
                                  <SVG
                                    className='h-75 align-self-end'
                                    src={toAbsoluteUrl(
                                      '/media/svg/avatars/001-boy.svg'
                                    )}
                                  ></SVG>
                                </span>
                              </div>
                              <div>
                                <a
                                  href='#'
                                  className='text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg'
                                >
                                  {p.firstName} {p.lastName}
                                </a>
                                <span className='text-muted font-weight-bold d-block'>
                                  {p.email}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span className='text-dark-75 font-weight-bolder d-block font-size-lg'>
                              {p.subscription}
                            </span>
                            <span className='text-muted font-weight-bold'>
                              {p.accountType}
                            </span>
                          </td>
                          <td>
                            <span className='text-dark-75 font-weight-bolder d-block font-size-lg'>
                              <Moment
                                format='ddd, MMMM DD, YYYY'
                                date={p.lastSignInTime}
                              />
                            </span>
                            <span className='text-muted font-weight-bold'>
                              <Moment
                                format='hh:mm A'
                                date={p.lastSignInTime}
                              />
                            </span>
                          </td>
                          <td>
                            <span className='text-dark-75 font-weight-bolder d-block font-size-lg'>
                              {p.properties?.length} Property(s)
                            </span>
                            <span className='text-muted font-weight-bold'>
                              Web, UI/UX Design
                            </span>
                          </td>
                          <td>
                            <span className='text-dark-75 font-weight-bolder d-block font-size-lg'>
                              {p.emailVerified ? 'Verified' : 'Not Verified'}
                            </span>
                            <span className='text-muted font-weight-bold d-block'></span>
                          </td>
                          <td className='pr-0 text-right'>
                            <a
                              href='#'
                              className='btn btn-light-success font-weight-bolder font-size-sm'
                            >
                              View Profile
                            </a>
                          </td>
                        </tr>
                      ))}
                    </>
                  )}
                </tbody>
              </table>
            </div>
            {/* end::Table */}
          </div>
        </div>
        {/* end::Body */}
      </div>
      {/* end::Advance Table Widget 9 */}
    </>
  );
}

const mapStateToProps = ({ usersListState }: IRootState) => ({
  userList: usersListState.entities,
  filteredList: usersListState.filteredEntities,
  loading: usersListState.loading,
});

const mapDispatchToProps = {
  getUserList: userReducer.actions.getUserList,
  filterList: userReducer.actions.filterList,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ListUsers);
