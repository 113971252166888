import React, { Suspense, lazy } from 'react';
import { Redirect, Switch, Route, Link } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
import ListUsers from './modules/Profile/profile';
import { DashboardPage } from './pages/DashboardPage';

const UserProfilepage = lazy(() =>
  import('./modules/UserProfile/UserProfilePage')
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  // const ECommercePage = lazy(() =>
  //   import('./modules/ECommerce/pages/eCommercePage')
  // );

  const Profiles = lazy(() => import('./modules/profiles'));

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          /*<Redirect exact from='/' to='/dashboard' />*/
          <Redirect exact from='/' to='/dashboard' />
        }
        <ContentRoute path='/dashboard' component={DashboardPage} />
        <ContentRoute path='/users/list' component={ListUsers} />
        <Route path='/profiles' component={Profiles} />
        <Route path='/user-profile' component={UserProfilepage} />
        <Redirect to='error/error-v1' />
      </Switch>
    </Suspense>
  );
}
