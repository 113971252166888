import {
  IProfile,
  defaultValue,
} from '../../app/shared/models/profileOld.model';
import {
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../app/shared/reducers/action-type.util';
import firebase from '../../firebase';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

if (process.env.REACT_APP_ENV === 'dev') {
  firebase.functions().useEmulator('localhost', 5001);
}

export const ACTION_TYPES = {
  FETCH_PROFILE_LIST: 'profile/FETCH_PROFILE_LIST',
  FETCH_USER_DETAILS: 'profile/FETCH_USER_DETAILS',
  FILTER_LIST: 'profile/FILTER_LIST',
};

const initialState = {
  errorMessage: null,
  updating: false,
  updateSuccess: false,
  loading: false,
  entity: defaultValue,
  entities: [] as ReadonlyArray<IProfile>,
  filteredEntities: [] as ReadonlyArray<IProfile>,
};

export type UserListState = Readonly<typeof initialState>;

// Reducer

export const reducer = (
  state: UserListState = initialState,
  action
): UserListState => {
  switch (action.type) {
    case FAILURE(ACTION_TYPES.FETCH_PROFILE_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };

    case REQUEST(ACTION_TYPES.FETCH_USER_DETAILS):
    case REQUEST(ACTION_TYPES.FETCH_PROFILE_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_PROFILE_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload,
        filteredEntities: action.payload,
      };

    case ACTION_TYPES.FILTER_LIST:
      return {
        ...state,
        loading: false,
        filteredEntities: state.entities.filter(
          (p) => p.accountType === action.payload
        ),
      };

    default:
      return state;
  }
};

// Actions
export const actions = {
  getUserList: (page?, size?, sort?) => ({
    type: REQUEST(ACTION_TYPES.FETCH_PROFILE_LIST),
  }),
  filterList: (filter: string) => ({
    type: ACTION_TYPES.FILTER_LIST,
    payload: filter,
  }),
};

// sagas
export function* saga() {
  yield takeLatest(
    REQUEST(ACTION_TYPES.FETCH_PROFILE_LIST),
    function* handleGetAllUsers() {
      try {
        const { data: payload } = yield call(requestAllUsers);
        yield put({ type: SUCCESS(ACTION_TYPES.FETCH_PROFILE_LIST), payload });
      } catch (e) {
        yield put({ type: FAILURE(ACTION_TYPES.FETCH_PROFILE_LIST) });
      }
    }
  );
}

// Requests
const requestAllUsers = () => {
  return axios.get(
    process.env.REACT_APP_API_URL + '/profiles'
  );
};

export const getUserPropertyCount = (userId: string) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve('foo');
    }, 300);
  });
}