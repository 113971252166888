import React from "react";
import MainDashboard from "../../_metronic/_partials/dashboards/MainDashboard";

/**
 * TODO:
 * - add disable user with a confirm dialog and hard delete option
 * - change twitter photo to a logo
 * - send a welcome email on sign up
 * - send a welcome email to all users
 * - send a verify email to unverified users
 * - activate the contact us form
 * - bug - userAuth event isn't working when user activate their account.
 *
 */

export function DashboardPage() {
  return <MainDashboard />;
}
