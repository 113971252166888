const getPreviousMonth = (noOfMonths) => {
  const today = new Date()
  const anotherDate = new Date(today.getFullYear(), today.getMonth() - noOfMonths, today.getDate());
  return anotherDate;
};

const getPreviousMonths = (noOfMonths) => {
  const statMonths = [];
  for (let i = 0; i < noOfMonths; i++) {
    const month = getPreviousMonth(i);
    const monthName = month.toLocaleString('default', { month: 'short' });
    const first = new Date(month.getFullYear(), month.getMonth(), 1);
    const last = new Date(month.getFullYear(), month.getMonth() + 1, 0);
    statMonths.push(
      {name: monthName, start: first, end: last}
    );
  }

  return statMonths;
};

const getCount = (profiles, stat, fieldName) => {
  const start = new Date(stat.start);
  const end = new Date(stat.end);

  return profiles.filter(user => {
    const dd = new Date(user[fieldName]._seconds * 1000);
    return dd >= start && dd <= end;
  }).length;
}

const buildProfilesMonthlyStats = (profiles, noOfMonths) => {
  const signUps = [];
  const logIns = [];
  const statMonths = getPreviousMonths(noOfMonths);
  statMonths.forEach(stat => {
    signUps.push({name: stat.name, count: getCount(profiles, stat, 'createdDate')});
    logIns.push({name: stat.name, count: getCount(profiles, stat, 'lastLogin')});
  });

  return {signUps, logIns};
}

export const buildProfilesChartOptions = (profiles, noOfMonths = 6) => {
  const stats = buildProfilesMonthlyStats(profiles, noOfMonths);
  return {
      labels: stats.logIns.map(login => login.name),
      bars: [
        {
          name: "New Signups",
          data: stats.signUps.map(stat => stat.count),
        },
        {
          name: "Logged in",
          data: stats.logIns.map(stat => stat.count),
        },
      ]
  };
}

export const buildProfilesData = (profiles) => {
  return {
    total: profiles.length,
    verified: profiles.filter(user => user.emailVerified === true).length,
    unverified: profiles.filter(user => user.emailVerified === false).length,
    subscribers: profiles.filter(user => user.subscription !== 'basic').length
  };
}