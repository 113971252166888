import {
  getStats,
} from './requests';
import {
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../../shared/reducers/action-type.util';
import { call, put, takeLatest } from '@redux-saga/core/effects';
import { ACTION_TYPES } from './actions';
import { buildProfilesChartOptions, buildProfilesData } from '../../../shared/services/stats.service';
import { findProfiles } from '../../profiles/_redux/requests';

const buildQueryParams = (type) => {
  return {
    filter: {
      status: '',
      type,
      searchText: '',
    },
    sortOrder: 'desc',
    sortField: 'lastLogin',
    pageNumber: 1,
    pageSize: 20000,
  }
};

// sagas
export function* saga() {

  yield takeLatest(
    REQUEST(ACTION_TYPES.FETCH_PERSONAL_STATS),
    function* handleGetStats(action: any) {
      try {
        const {data: profiles} = yield call(findProfiles, buildQueryParams('personal'));
        const payload = {
          chartOptions: buildProfilesChartOptions(profiles),
          profilesStat: buildProfilesData(profiles)
        };

        yield put({ type: SUCCESS(ACTION_TYPES.FETCH_PERSONAL_STATS), payload });
      } catch (e) {
        console.error('Failed to fetch stats: ', e.message);
        yield put({
          type: FAILURE(ACTION_TYPES.FETCH_PERSONAL_STATS),
          payload: e.message,
        });
      }
    }
  );

  yield takeLatest(
    REQUEST(ACTION_TYPES.FETCH_BUSINESS_STATS),
    function* handleGetStats(action: any) {
      try {
        const {data: profiles} = yield call(findProfiles, buildQueryParams('business'));
        const payload = {
          chartOptions: buildProfilesChartOptions(profiles),
          profilesStat: buildProfilesData(profiles)
        };

        console.log(payload);
        yield put({ type: SUCCESS(ACTION_TYPES.FETCH_BUSINESS_STATS), payload });
      } catch (e) {
        console.error('Failed to fetch stats: ', e.message);
        yield put({
          type: FAILURE(ACTION_TYPES.FETCH_PERSONAL_STATS),
          payload: e.message,
        });
      }
    }
  );
}
