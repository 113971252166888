import {
  IProfile,
  defaultValue,
} from '../../app/shared/models/profileOld.model';
import {
  REQUEST,
  SUCCESS,
  FAILURE,
} from '../../app/shared/reducers/action-type.util';
import firebase from '../../firebase';
import { call, put, takeEvery, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';
import { IProperty } from '../../app/shared/models/property.model';

if (process.env.REACT_APP_ENV === 'dev') {
  firebase.functions().useEmulator('localhost', 5001);
}

export const ACTION_TYPES = {
  FETCH_USER_PROPERTY_LIST: 'user/FETCH_USER_PROPERTY_LIST',
};

const initialState = {
  errorMessage: null,
  updating: false,
  updateSuccess: false,
  loading: false,
  entity: defaultValue,
  entities: [] as ReadonlyArray<IProperty>,
};

export type UserPropertyListState = Readonly<typeof initialState>;

// Reducer

export const reducer = (
  state: UserPropertyListState = initialState,
  action
): UserPropertyListState => {
  switch (action.type) {
    case FAILURE(ACTION_TYPES.FETCH_USER_PROPERTY_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };

    case REQUEST(ACTION_TYPES.FETCH_USER_PROPERTY_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case SUCCESS(ACTION_TYPES.FETCH_USER_PROPERTY_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload,
      };

    default:
      return state;
  }
};

// Actions
export const actions = {
  getUserPropertyList: (userId?: string) => ({
    type: REQUEST(ACTION_TYPES.FETCH_USER_PROPERTY_LIST),
    userId,
  }),
};

// sagas
export function* saga() {
  yield takeEvery(
    REQUEST(ACTION_TYPES.FETCH_USER_PROPERTY_LIST),
    function* handleGetUserProperties(action: any) {
      try {
        const { data: payload } = yield call(
          requestUserProperties,
          action.userId
        );
        yield put({
          type: SUCCESS(ACTION_TYPES.FETCH_USER_PROPERTY_LIST),
          payload,
        });
      } catch (e) {
        yield put({ type: FAILURE(ACTION_TYPES.FETCH_USER_PROPERTY_LIST) });
      }
    }
  );
}

// Requests
const requestUserProperties = (userId: number) => {
  return axios.get(process.env.REACT_APP_API_URL + `/profiles/${userId}/properties`);
};
